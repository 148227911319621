@import '../../styles/variables.scss';

.tag-container {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-green;
  padding: 0 10px;
  border-radius: 4px;
  margin-right: 5px;
  span {
    font-size: 15px;
    color: $green-blue;
    white-space: nowrap;
  }
}
