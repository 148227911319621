@import "../../styles/variables";

.stats-container {
  width: 50%;
  height: 100%;

  .case-stats {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .case-stats-show {
      display: flex;
      justify-content: space-between;
      height: 91px;
      width: 100%;
      align-items: center;
    }
  }

  .campaign-stats {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .campaign-stats-editable {
      height: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .campaign-stats-status {
        width: 30%;
        position: relative;
        line-height: 30px;
        font-size: 30px;
      }

      .status-control {
        width: 45%;
        display: flex;
        position: relative;

        button {
          outline: none;
          border: none;
          cursor: pointer;
          background-color: #F8F8F8;
          height: 30px;
          width: 33.33%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 10px;
            margin-right: 5px;
          }

          span {
            font-size: 13px;
            line-height: 15px;
            color: #595959;
          }
        }

        .start {
          border: 1px solid #ACACAC;
          border-right: 0.5px solid #ACACAC;
          border-radius: 3px 0 0 3px;

          &:hover {
            background-color: $light-green;
            border-color:  #e20616;//$green-blue;
            span {
              color: #F92232;//$green-blue;
            }
          }

          &.disabled {
            border-color: #CFCFCF;
            background-color: transparent;
            opacity: 0.6;
            pointer-events: none;
          }
        }

        .pause {
          border: 1px solid #ACACAC;
          border-left: 0.5px solid #ACACAC;
          border-right: 0.5px solid #ACACAC;
          opacity: 0.9;

          &:hover {
            background-color: $light-green;
            border-color:  #e20616;//$green-blue;
            span {
              color: #F92232;//$green-blue;
            }
          }

          &.disabled {
            border-color: #CFCFCF;
            border-right: none;
            border-left: none;
            background-color: transparent;
            opacity: 0.6;
            pointer-events: none;
          }
        }

        .finish {
          border-radius: 0 3px 3px 0;
          opacity: 0.9;
          border: 1px solid #ACACAC;
          border-left: 0.5px solid #ACACAC;

          &:hover {
            background-color: $light-green;
            border-color:  #e20616;//$green-blue;
            span {
              color: #F92232;//$green-blue;
            }
          }

          &.disabled {
            background-color: transparent;
            opacity: 0.6;
            border-color: #CFCFCF;
            pointer-events: none;
          }
        }

      }

      .campaign-stats-edit {
        width: 15%;
        height: 30px;
        display: flex;
        justify-content: flex-end;

        &.disabled{
          button{
            opacity: 0.5 !important;
            pointer-events: none !important;
          }
        }

        button {
          display: flex;
          align-items: center;
          width: auto;
          height: 100%;
          outline: none;
          border: none;
          background-color: transparent;
          cursor: pointer;
          padding: 0;
          color: #F92232;//$green-blue;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.2px;

          img {
            height: 20px;
            margin-left: 10px;
          }
        }
      }
    }

    .campaign-stats-show {
      display: flex;
      justify-content: space-between;
      height: 60%;
      align-items: center;
    }
  }
}