@import '../../styles/variables.scss';

.settings-bot-row-container {
  width: 92%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  margin: 5px 0;
  box-sizing: border-box;

  .settings-bot {
    padding: 15px 20px;
    min-height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .settings-bot-collapse-btn {
      width: 30%;

      button {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 10px;
          margin-right: 15px;
        }

        span {
          font-size: 18px;
          color: $green-blue;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
        }
      }
    }

    .settings-bot-count {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      color: $deep-purple;
      opacity: 0.57;
    }

    .settings-bot-actions {
      display: flex;
      justify-content: flex-end;
      width: 20%;

      button:first-child {
        margin-right: 16px;
      }

      button {
        height: 50%;
        outline: none;
        border: none;
        padding: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          height: 20px;
        }
      }
    }
  }

  .settings-bot-collapse {
    box-sizing: border-box;
    height: 99px;
    border-top: 1px solid #cfcfcf;
    padding: 18px 50px 15px 50px;
    position: relative;
  }
}