@import '../../styles/variables.scss';

.settings-sidebar-container {
  min-height: 100%;
  width: 17%;
  background-color: $light-green;

  .type:hover {
    background-color: #e1ebff;
    border-bottom: 1px solid #e1ebff;
    border-right: 3px solid $green-blue;
  }

  .type {
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    padding: 0;
    padding-left: 10%;
    justify-content: flex-start;
    align-items: center;
    border: none;
    border-bottom: 1px solid white;
    border-right: 3px solid transparent;
    background-color: transparent;

    &.active {
      background-color: #e1ebff;
      border-bottom: 1px solid #e1ebff;
      border-right: 3px solid $green-blue;

      span {
        color: $green-blue;
      }
    }

    span {
      color: $medium-grey;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
    }
  }
}
