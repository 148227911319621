.add-tags-container {
  display: flex;
  flex-wrap: wrap;
  //overflow-y: auto;
  height: 68px;
  margin-top: -4px;
  width: 100%;

  .add-input-container {
    height: 30px;
    min-width: 30%;
    display: flex;
    align-items: center;
    border: 1px solid #cfcfcf;
    border-radius: 2px;
    margin-right: 4px;
    box-sizing: border-box;
    margin-top: 4px;

    .add-select {
      height: 100%;
      width: 100%;
      color: rgba(0, 18, 75, 0.5);
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.55px;
      outline: none;
      border: none;
      box-sizing: border-box;

      .select__value-container {
        padding: 0;

        .select__placeholder {
          color: rgba(0, 18, 75, 0.5);
          width: 100%;
          font-size: 12px;
          letter-spacing: 0.55px;
          line-height: 15px;
          white-space: nowrap;
        }
      }

      .select__control {
        &.select__control--is-focused, &.select__control--menu-is-open {
          border: none;
          box-shadow: none;
        }
      }

      .select__indicators {
        display: none;
      }

      .select__menu {
        box-sizing: border-box;
        margin: 0;
        width: calc(100% + 19px);
        height: auto;
        padding: 0;
        border-radius: 0 0 2px 2px;

        .select__menu-list {
          .select__option {
            line-height: 15px;
            max-height: 30px;
            color: rgba(0, 18, 75, 0.5);

            &.select__option--is-focused {
              background-color: white;
            }
          }
        }
      }
    }

    button {
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 10px;
      }
    }
  }
}
