@import "../../styles/variables";

.case-page-container {
  min-height: calc(100% - 70px);
  height: auto;
  display: flex;

  .case-page {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .case-page-content {
      width: 86%;
      min-height: calc(100% - 70px);
      height: auto;
      padding-bottom: 30px;
      box-sizing: border-box;

      .case-page-content-header {
        display: flex;
        width: 100%;
        height: 180px;
        border-bottom: 1px solid #E7E7E7;
        opacity: 0.89;
        padding-bottom: 30px;
        box-sizing: border-box;
        cursor: default;
      }

      .case-page-content-body {
        width: 100%;
        display: flex;
        flex-direction: column;

        .calls-count {
          height: 50px;
          color: $deep-purple;
          display: flex;
          align-items: center;

          span {
            font-size: 13px;
            font-family: 'Montserrat', sans-serif;
            line-height: 20px;
            letter-spacing: 0.67px;
          }
        }

        .calls-container {
          display: flex;
          justify-content: space-between;
          height: auto;

          .calls {
            width: 50%;
            overflow-y: auto;
            height: 510px;
            position: relative;

            &.empty {
              padding-top: 30px;
              box-sizing: border-box;
            }
          }

          .call-details {
            width: 48%;
            position: relative;
          }
        }
      }
    }
  }
}