@import "../../styles/variables";

.bot-campaign-reporting-general-body {
  height: 86%;
  position: relative;

  .bot-campaign-reporting-filters {
    height: 11.627907%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bot-campaign-reporting-quick-filters {

      margin-left: auto;
      margin-right: 0;

      button {
        color: #ACACAC;
        cursor: pointer;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 2px 15px;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.67px;
        font-family: 'Montserrat', sans-serif;

        &.active {
          color: $deep-purple;
        }
      }
    }

    .bot-campaign-reporting-filter-helpers {
      display: flex;
      align-items: center;

      .date-picker-container {
        width: 100%;
      }
    }
  }

  .bot-campaign-reporting-content-container {
    //height: calc(88.372093% - 70px);
    //width: 86%;
    min-height: calc(100% - 70px);
    height: auto;
    padding-bottom: 50px;
    box-sizing: border-box;
    width: 100%;

    .bot-campaign-reporting-content-filter {
      height: 60px;
    }

    .bot-campaign-reporting-content {
      max-height: 100%;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 70px;

      div[class^='MuiGrid-item-'] {
        height: 160px !important;
      }

      a {
        height: 100%;
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

.MuiGrid-root .MuiGrid-item .MuiGrid-grid-xs-4 {
  max-width: 100%;
}

.card-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;

  .card-title {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      width: 90%;
      margin: 0;
      font-size: 17px;
      line-height: 22px;
      font-family: 'Montserrat', sans-serif;
      color: #F92232; //$green-blue;
      text-align: left;
      font-weight: normal;
    }

    span {
      text-align: left;
      color: #ACACAC;
      font-size: 15px;
      line-height: 15px;
    }
  }

  .card-data {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}