@import "../../styles/variables";



.forgot-password-page-container {
  height: 100%;
  width: 100%;

  .forgot-password-page {
    height: calc(100% - 170px);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .forgot-password {
      margin-top: 5%;
      height: 60%;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      &.sent {
        height: 56%;
        box-sizing: border-box;
        padding-bottom: 14px;

        img {
          height: 30px;
        }
      }

      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        color: $deep-purple;
        font-family: 'Montserrat', sans-serif;
      }

      .forgot-password-p, a {
        color: $deep-purple;
        text-align: center;
        line-height: 23px;
        font-size: 15px;
        opacity: 0.6;
      }

      .forgot-password-p {
        margin-top: 0;
        margin-bottom: 30px;
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}