@import '../../styles/variables';

.navbar-container {
  width: 100%;
  height: 70px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;

  .navbar {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 7%;

    .navbar-title {
      width: 78%;
      
      img {
        transform: scale(.8);
        height: 60px;
      }
    }

    .navbar-config {
      width: 7%;
      height: 100%;
      display: flex;

      &.active {
        background-color: $light-green;
      }

      a {
        height: 100%;
        width: 100%;
        padding: 0;
        background-color: transparent;
        border: none;
        border-left: 1px solid #e7e7e7;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 25px;
        }
      }
    }

    .navbar-dropdown {
      height: 100%;
      width: 16%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & > div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-self: center;
        left: 0.2% !important;
        z-index: 10;

        div[class^='MuiPaper-root-'] {
          width: 100%;
          margin-right: 6%;

          ul {
            li {
              color: $deep-purple;
              opacity: 0.57;

              .navbar-logout {
                font-size: 15px;
                text-decoration: none;
                color: $deep-purple;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: none;
                padding: 0;
                text-align: left;
                outline: none;
              }
            }
          }
        }
      }

      .navbar-profile {
        height: 100%;
        width: 100%;
        border: none;
        border-left: 1px solid #e7e7e7;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        outline: none;
        background-color: transparent;

        img {
          padding-left: 20px;
          height: 25px;
          opacity: 0.85;
        }

        span {
          padding-left: 10px;
          white-space: pre-wrap;
          text-align: left;
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
        }
      }


    }
  }
}

.pelikanus{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  img{
    height: 50px !important;
    transform: none !important;
  }
  span{
    color: #ACACAC;
    font-size: 12px;    
  }
}