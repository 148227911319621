@import "../../styles/variables";

.dropzone-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  &.import {
    height: 100%;
  }


  .error {
    font-size: 12px;
    margin-top: 1%;
    color: $pink;
    height: 15px;
    margin-bottom: 2%;
  }

  .file-container {
    width: 80px;
    height: 80px;
    border: 2.1px dotted #CFCFCF;
    background-color: #EFF3F9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    margin: 1px 0;
    position: relative;

    .file-cross {
      position: absolute;
      left: 60px;
      bottom: 60px;
      height: 10px;
      cursor: pointer;
    }

    span {
      color: $green-blue;
      font-size: 12px;
      width: 80%;

      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .dropzone-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropzone-flex-start{
      display: flex;
      align-items: center;
      gap:.2em;
      .img {
          width: 75%;
        }

    }

    label {
      color: $deep-purple;
      font-size: 15px;
      line-height: 23px;
      opacity: 0.57;
    }

    a {
      color: $green-blue;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        height: 15px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }

  .dropzone {
    &.import {
      height: 200px;
    }

    height: 80px;
    border: 3.1px dotted #CFCFCF;
    background-color: #EFF3F9;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;

    span {
      text-align: center;
      color: $deep-purple;
      font-size: 15px;
      line-height: 23px;
      opacity: 0.57;
    }
  }
}
