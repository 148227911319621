@import "../../styles/variables";

// .bot-campaigns-navbar-container {
//     height: 9%;
//     padding-right: 8.75%;
//
//     .bot-campaigns-navbar {
//         height: 100%;
//         display: flex;
//         align-items: center;
//         border-bottom: 1px solid rgba(231, 231, 231, 0.89);

.bot-campaigns-report-navbar {
    display: flex;
    align-items: center;
    width: 40%;

    li {
        list-style-type: none;
    }

    li+li {
        margin-left: 2%;
    }

    .bot-campaigns-report-navbar-section {
        color: rgba(0, 18, 75, 0.6);
        font-size: 16px;
        letter-spacing: 0.95px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
        &:hover{
            color: $deep-purple;

        }

        &.active {
            color: $deep-purple;
        }
    }
}

//     }
// }