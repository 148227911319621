@import '../../styles/variables';

.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p {
    font-style: italic;
    color: $deep-purple;
    opacity: 0.57;
    font-size: 17px;
    text-align: center;
  }
}