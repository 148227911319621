@import '../../styles/variables';

.progress-container {
  width: 54%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.card_custom {
    width: 100% !important;
    gap:.2em;
  }

  &.big {
    width: 90%;

    .progress {
      width: 90%;
    }

    .progress-percentage {
      span {
        font-weight: 500;
        font-size: 17px;
        line-height: 15px;
      }
    }
  }

  .progress {
    height: 8px;
    width: 75%;
    background-color: #CFCFCF;
    border-radius: 4px;

    .progress-color {
      background-color: #e20616;//$green-blue;
      border-radius: 4px;
      height: 8px;
    }
  }

  .progress-percentage {
    span {
      color: #F92232;//$green-blue;
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;
    }
  }
}