@import "./variables.scss";

html, body, #root {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  margin: 0;
  overflow-y: auto;
  font-family: Roboto, sans-serif;
}

input, textarea, select, button, span, h1, h2, h3, h4, h5, h6, p {
  font-family: Roboto, sans-serif;
}

