
.export-container:hover{
  cursor: pointer;
  span{
    color: #777B8D;
  }
}
.export-container {
  height: 60px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  outline: none;
  border:none;
  background-color: transparent;
  
  span {
    color: rgba(0,18,75,0.5);
    font-size: 15px;
    line-height: 18px;
  }

  img {
    height: 15px;
    margin-left: 8px;
  }
}