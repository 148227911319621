@import '../../styles/variables';

.settings-page-container {
  min-height: calc(100% - 70px);
  height: auto;
  display: flex;
  .settings-page {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      height: 40px;
      width: 86%;
      display: flex;
      align-items: flex-end;

      h4 {
        color: $green-blue;
        font-size: 28px;
        line-height: 36px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin: 0;
      }
    }

    .settings-page-content {
      width: 86%;
      min-height: calc(100% - 110px);
      height: auto;
      padding-bottom: 30px;
      box-sizing: border-box;

      .btn {
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        button {
          outline: none;
          border: none;
          background-color: transparent;
          cursor: pointer;
          padding: 0;
          display: flex;
          align-items: center;

          img {
            height: 20px;
            margin-right: 8px;
          }

          span {
            color: $green-blue;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.2px;
          }
        }
      }

      .settings-page-main {
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        width: 100%;
        display: flex;
        height: auto;
        min-height: 500px;

        .settings-page-list {
          width: 83%;
          height: 500px;
          overflow-y: auto;
          padding: 15px 4%;
          position: relative;
        }
      }
    }
  }
}
