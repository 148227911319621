@import '../../styles/variables.scss';

.settings-tag-container {
  background-color: $light-green;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 2px;
  margin-right: 4px;
  height: 30px;
  margin-top: 4px;
  span {
    color: $green-blue;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.55px;
  }

  button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
      height: 10px;
    }
  }
}
