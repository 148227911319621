@import '../../styles/variables';

.initial-navbar-container {
  height: 170px;
  width: 100%;
  background-color: transparent;
  box-shadow: 4px 4px 12px rgba(0,0,0, 0.1);

  .initial-navbar {
    background-color: white;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 87%;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-left: 30px;
        height: 75%;
      }

      span {
        border-left: 1px solid $deep-purple;
        font-size: 15.1px;
        line-height: 23px;
        letter-spacing: 2.24px;
        margin-left: 30px;
        padding-left: 30px;
        height: 75%;
        display: flex;
        align-items: center;
        color: $deep-purple;
        font-family: 'Montserrat', sans-serif;
        opacity: 0.73;
      }
    }
  }
}
.pelikanus{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  img{
    height: 50px !important;
    transform: none !important;
  }
  span{
    color: #ACACAC;
    font-size: 12px;    
  }
}