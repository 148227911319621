@import "../../styles/variables";

.home-page-container {
  height: calc(100vh - 71px);
  display: flex;
  overflow: hidden;
  
  .home-page {
    height: 100%;
    width: 100%;
    display: flex;
    
    .home-page-sidebar {
      height: 100%;
      width: 10%;
    }
    
    .home-page-content {
      overflow: auto;
      // border: 1px solid red;
      width: 90%;
      height: 100%;
    }
  }
}