
.campaign-page-container {
  min-height: calc(100% - 70px);
  height: auto;
  display: flex;

  .campaign-page {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .campaign-page-content {
      width: 86%;
      min-height: calc(100% - 70px);
      height: auto;
      padding-bottom: 50px;
      box-sizing: border-box;

      .campaign-page-content-header {
        display: flex;
        width: 100%;
        height: 200px;
        border-bottom: 1px solid #E7E7E7;
        opacity: 0.89;
        padding-bottom: 20px;
        box-sizing: border-box;
      }

      .campaign-page-content-filter {
        height: 60px;
      }
    }
  }
}