@import "../../styles/variables";

.login-verify-page-container {
  height: 100%;
  width: 100%;

  .login-verify-page {
    height: calc(100% - 170px);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .login-verify {
      margin-top: 5%;
      height: 60%;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Roboto', sans-serif;

      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        color: $deep-purple;
        font-family: 'Montserrat', sans-serif;
        opacity: 0.8;
      }

      .inputs {
        max-height: 200px;
        height: 30%;
        padding-bottom: 5%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      a {
        font-size: 15px;
        line-height: 23px;
        color: $deep-purple;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.57;
      }
    }
  }
}