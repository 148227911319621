@import "../../styles/variables";

.container-new{
  height: 90vh;
  overflow-y: auto;
}

.new-campaign-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 4rem;
  padding-bottom: 1rem;
  
  .cross {
    position: absolute;
    right: 5%;
    top: 3%;
    height: 15px;
    cursor: pointer;
    &.processed-with-errors {
      top: 3%;
    }
  }
  
  .error-campaign {
    &.processed-with-errors  {
      height: 70%;
        justify-content: space-around;
    }

    .error-campaign-title {
      height: 18%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        height: calc(50% + 12.5px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2.65px;
      }
    }
    .error-campaign-content {
      width: 100%;
      height: 64%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      color: $deep-purple;
      opacity: 0.57;
      padding-bottom: 20px;

      &.processed-with-errors {
        height: auto;
        .error-campaign-subtitle {
          text-align: center;
        }
      }
      
      .error-campaign-subtitle {
        font-size: 16px;
        line-height: 23px;
      }
      
      .bold{
        font-weight: 500;
      }
      .log-details {
        padding: 15px 10px;
        height: 75%;
        overflow-y: auto;
        background-color: $light-green;
        box-sizing: border-box;
        .log-entry {
          ul {
            line-height: 23px;
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }
    .error-campaign-btns {
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 75%;

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
      }         

      .ok {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
        padding-top: 42px;        
      }
    }
  }

  .new-campaign, .error-campaign {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .xhr {
      opacity: 0.2;
      pointer-events: none;
    }

    .new-campaign-title {

      height: 18%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2.65px;
      }
    }

    .new-campaign-content {
      width: 100%;
      height: 64%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px 0;
      box-sizing: border-box;

      .error {
        font-size: 12px;
        margin-top: 1%;
        color: $pink;
        height: 15px;
        margin-bottom: 2%;
      }

      .new-campaign-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid #CFCFCF;
          font-size: 28px;
          line-height: 42px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          outline: none;
          color: $green-blue;
        }

        input::placeholder {
          color: #CFCFCF;
        }
      }

      .new-campaign-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 1px;
        }

        textarea {
          box-sizing: border-box;
          border: 1.1px solid #CFCFCF;
          border-radius: 2px;
          resize: none;
          outline: none;
          width: 100%;
          height: 60px;
          font-size: 15px;
          line-height: 23px;
          color: $deep-purple;
          overflow-y: auto;
          padding: 5px 10px;
          font-family: Roboto, sans-serif;
        }

        textarea::placeholder {
          color: #CFCFCF;
        }
      }

      .new-campaign-result-filter {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
  
        .label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 1px;
        }

        .add-tags-container {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          margin-top: -4px;
          margin-bottom: 4%;
          width: 100%;
        }
      }

      .new-campaign-phone-priority {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        margin-bottom: 4%;

        label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 2px;
          white-space: nowrap;
        }

        .phone-priority {
          border: 1.1px solid #CFCFCF;
          border-radius: 2px;
          display: flex;
          align-items: center;
          height: 30px;
          justify-content: space-between;
          box-sizing: border-box;

          img {
            height: 20px;
            margin-left: 10px;
            margin-right: 15px;
          }

          span {
            margin-right: 50%;
            color: $deep-purple;
            font-size: 15px;
            line-height: 23px;
          }

          .phone-priority-btns {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 30px;

            button {
              height: 15px;
              padding: 0;
              cursor: pointer;
              outline: none;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #EFF0F9;
            }

            .up {
              img {
                height: 7px;
                margin: 0;
                transform: rotateX(180deg);
              }
            }

            .down {
              img {
                height: 7px;
                margin: 0;
              }
            }
          }

        }
      }

      .new-campaign-contactability {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .label-contactability {
          display: flex;
          align-items: center;
        }

        .img {
          width: 75%;
        }

        .label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 1px;
          margin-right: 2px;
        }

        input {
          box-sizing: border-box;
          border: 1.1px solid #CFCFCF;
          border-radius: 2px;
          resize: none;
          outline: none;
          width: 100%;
          font-size: 15px;
          line-height: 23px;
          color: $deep-purple;
          padding: 5px 10px;
          font-family: Roboto, sans-serif;
        }

        input::placeholder {
          color: #b6b6b6;
        }
      }

      .new-campaign-dropzone {
        width: 100%;
        margin-bottom: -10%;
      }
    }


    .new-campaign-btns {
      margin-top: 6%;
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
      }

      .next {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
      }

      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  .img{
    opacity: .6;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #00124B;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -100px;
  padding: 1em .5em;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}