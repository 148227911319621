@import '../../styles/variables';

.call-details-container {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e20616;//$green-blue;
  height: 510px;
  overflow-y: auto;
  padding: 10px 20px;
  box-sizing: border-box;

    .call-details-title {
      height: 38px;
      display: flex;
      align-items: center;

      span {
        color: $deep-purple;
        letter-spacing: 0.67px;
        line-height: 20px;
        font-size: 13px;
        opacity: 0.8;
        font-family: 'Montserrat', sans-serif;
      }
    }

    .call-details-content {
      width: 100%;
      height: calc(100% - 38px);

      .call-detail-chats{
        height: 100%;
        overflow-y: auto;

        .rce-mbox{
          max-width: 50%;
          white-space: pre-line;
        }

        .rce-avatar-container{
          border-radius: 20px;
        }
        
      }

    }
}

audio{
  margin-top: 10px;
  width: 100%;
}