@import '../../styles/variables.scss';

.new-bot-form-container {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .cross {
    position: absolute;
    right: 5%;
    top: 3%;
    height: 15px;
    cursor: pointer;
  }

  .new-bot-form-content-success {
    height: 82%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 30px;
      margin-bottom: 60px;
    }

    span {
      color: $deep-purple;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 2.65px;
      opacity: 0.8;
      font-family: 'Montserrat', sans-serif;
    }
  }


  .new-bot-form-btns {
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &.success {
      width: 75%;

      .ok {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      outline: none;
      border: none;
      border-radius: 22.5px;
      cursor: pointer;
    }

    .next {
      color: white;
      background-color: $green-blue;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.93px;
      width: 65%;
      height: 42px;
    }

    .cancel {
      color: $green-blue;
      line-height: 20px;
      font-size: 17px;
      width: 65%;
      height: 42px;
      background-color: transparent;
    }
  }

  .new-bot-form {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .xhr {
      opacity: 0.2;
      pointer-events: none;
    }

    .new-bot-form-title {
      height: 18%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2.65px;
      }
    }

    .new-bot-form-content {
      height: 64%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      input::placeholder {
        color: #cfcfcf;
      }

      .new-bot-form-input {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid #cfcfcf;
          font-size: 28px;
          line-height: 26px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          outline: none;
          color: $green-blue;
          box-sizing: border-box;
        }

        label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 1px;

          &.error {
            font-size: 12px;
            margin-top: 1px;
            color: $pink;
            height: 15px;
            opacity: 1;
          }
        }
      }
    }
  }
}