@import '../../styles/variables';

.filters-container {
  width: 100%;
  height: 100%;
  display: flex;
  
  .filters-content-delete {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .selected {
      width: 15%;
      max-width: 150px;
      
      span {
        color: $deep-purple;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.67px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    
    .delete {
      width: 15%;
      max-width: 150px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      outline: none;
      background-color: transparent;

      span {
        color: #F92232;//$green-blue;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        margin-left: 8px;
      }
      
      img {
        height: 15px;
      }
    }
  }
  
  .filters-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .filters {
      width: 100%;
      // white-space: nowrap;
      button {
        cursor: pointer;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
        color: #ACACAC;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.67px;
        margin-right: 20px;
        
        &.selected {
          color: $deep-purple;
        }
        &:hover{
          color: $deep-purple;

        }
      }
    }
    
    .btns {
      display: flex;
      white-space: nowrap;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0;
        outline: none;
        border: none;
        background-color: transparent;

        &.import {
          margin-left: 20px;
        }

        span {
          color: #F92232;//$green-blue;
          font-size: 13px;
          line-height: 15px;
          font-weight: 500;
        }

        img {
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}