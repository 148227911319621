@import '../../styles/variables';

.status-value-container {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.3px;

  &.contacted {
    background-color: $light-green;

    &.small {
      width: 45%;
    }

    span {
      color: $green-blue;
    }
  }

  &.pink {
    background-color: rgba(231, 74, 120, 0.19);

    span {
      color: $pink;
    }
  }

  &.green {
    background-color: $promise-green-bkg;

    span {
      color: $promise-green-text;
    }
  }

  &.grey {
    background-color: #F8F8F8;

    span {
      color: rgba(0, 18, 75, 0.8);
    }
  }

  &.notContacted {
    background-color: #F8F8F8;

    &.pink {
      width: 45%;
    }

    span {
      color: rgba(0, 18, 75, 0.8);
    }

    &.bot {
      background-color: rgba(231, 74, 120, 0.19);

      span {
        color: #D93464;
      }
    }
  }

  span {
    letter-spacing: 0.55px;
    font-size: 12px;
    line-height: 15px;
  }
}

.status-container {
  border-radius: 3.3px;
  width: 40%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 45%;
    min-width: 270px;
    height: 30px;
  }

  &.ongoing,
  &.success {
    background-color: #EFF3F9;
    color: $green-blue;

    &.big {
      border: 1px solid $green-blue;
    }
  }

  &.paused,
  &.failure,
  &.pink {
    background-color: rgba(231, 74, 120, 0.19);
    color: #d93464;

    &.big {
      border: 1px solid #d93464;
    }
  }

  &.scheduled {
    background-color: #F8F8F8;
    color: rgba(0, 18, 75, 0.8);

    &.big {
      border: 1px solid rgba(0, 18, 75, 0.57);
    }
  }
  
  &.detained,
  &.closed {
    background-color: #F8F8F8;
    color: rgba(0, 18, 75, 0.8);

    &.big {
      border: 1px solid rgba(0, 18, 75, 0.8);
    }
  }

  &.big {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
  }

  .status {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      vertical-align: center;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.55px;
    }
  }
}

.status-value-container-export{
  background-color: rgba(0, 102, 202, 0.1);
  color: #0066CA; 
  padding: .6em .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.3px;
  &:hover{
    transform: scale(1.02);
  }
  cursor: pointer;
}
.disabled { 
  opacity: .4;
  cursor: not-allowed;
  &:hover{
    transform: none;
  }
}