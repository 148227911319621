@import '../../styles/variables.scss';

.error-campaign {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .error-campaign-title {
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    span {
      height: calc(50% + 12.5px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $deep-purple;
      font-size: 16px;
      line-height: 25px;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 2.65px;
    }
  }

  .error-campaign-content {
    width: 100%;
    height: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    color: $deep-purple;
    opacity: 0.57;

    &.processed-with-errors {
      height: auto;

      .error-campaign-subtitle {
        text-align: center;
      }
    }

    .error-campaign-subtitle {
      font-size: 16px;
      line-height: 23px;
    }
  }

  .error-campaign-btns {
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    button {
      outline: none;
      border: none;
      border-radius: 22.5px;
      cursor: pointer;
    }

    .ok {
      color: white;
      background-color: $green-blue;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.93px;
      width: 65%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel {
      color: $green-blue;
      line-height: 20px;
      font-size: 17px;
      width: 65%;
      height: 42px;
      background-color: transparent;
      padding-top: 42px;
    }
  }
}

.settings-delete-confirmation, .coming-soon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cross {
    position: fixed;
    right: 34%;
    top: 38%;
    height: 15px;
    cursor: pointer;
  }

  .settings-delete-confirmation-content, .coming-soon-content {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .settings-delete-confirmation-text, .coming-soon-text {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 10%;

      h5 {
        margin: 0;
        color: $deep-purple;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 2.65px;
        text-align: center;
      }

      span {
        margin-top: 10px;
        color: $deep-purple;
        font-size: 15px;
        line-height: 23px;
        opacity: 0.57;
        text-align: center;
      }
    }

    .settings-delete-confirmation-btns, .coming-soon-btn {
      height: 25%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &.toDelete {
        button {
          width: 50%;
        }

        .delete {
          background-color: $pink;
        }
      }

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 1.93px;
        width: 35%;
        height: 34px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }

      .delete {
        color: white;
        background-color: $green-blue;
        margin-left: 10px;
      }

      .cancel {
        color: $green-blue;
        background-color: white;
        border: 1px solid $green-blue;
      }
    }
  }
}
