@import '../../styles/variables';

.case-info-container {
  width: 50%;
  position: relative;

  .case-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #2278f9;//$green-blue;
      font-size: 28px;
      line-height: 36px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }

    .info {
      display: flex;
      width: 80%;
      justify-content: space-between;
      margin-bottom: 10px;

      .left, .right {
        width: 45%;
        border-top: 1px solid #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
      }

      .ind:first-child {
        border-bottom: 1px solid #E7E7E7;
      }

      .ind {
        height: 34px;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 15px;
          line-height: 23px;
          color: $deep-purple;
          opacity: 0.57;
        }
      }
    }
  }
}