@import "../../styles/variables";

// .bot-inbound-navbar-container {
//     height: 9%;
//     padding-right: 8.75%;
//
//     .bot-inbound-navbar {
//         height: 100%;
//         display: flex;
//         align-items: center;
//         border-bottom: 1px solid rgba(231, 231, 231, 0.89);

.bot-inbound-report-navbar {
    display: flex;
    align-items: center;
    width: 40%;
    flex-shrink: 0;
    padding-left: 0;

    li {
        list-style-type: none;
    }

    li+li {
        margin-left: 2%;
    }

    .bot-inbound-report-navbar-section {
        color: rgba(0, 18, 75, 0.6);
        font-size: 16px;
        letter-spacing: 0.95px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;

        &.active {
            color: $deep-purple;
        }
    }
}

//     }
// }