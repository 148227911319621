
.breadcrumbs {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 7%;

  a, button {
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;

    img {
      margin-right: 20px;
    }

    span {
      color: #8B8B8B;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.95px;
      font-family: 'Montserrat', sans-serif;
    }
  }
}