@import '../../styles/variables';

.campaign-info-container {
  width: 50%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  &.campaign {
    .campaign-info {
      h4 {
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }

  .campaign-info {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    .campaign_progress{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap:1em;
      width: 100%;
      line-height: 16px !important;
      font-size: 16px !important;

      .title{
        font-size: 14px !important;
        line-height: 14px;
        white-space: pre;
        color: #6b769b !important;       // font-weight: 500;
        // font-family: 'Montserrat', sans-serif; 
      }
    }

    h4 {
      margin: 0;
      color: #F92232;//$green-blue;
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
    }

    .campaign-info-description {
      color: $deep-purple;
      font-size: 15px;
      line-height: 23px;
      opacity: 0.57;
    }
  }
}