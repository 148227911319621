@import '../../styles/variables.scss';

.settings-user-row-container {
  width: 92%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  padding: 15px 20px;
  margin: 5px 0;
  box-sizing: border-box;

  .settings-user-data {
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25%;
    h5 {
      margin: 0;
      font-size: 18px;
      color: $green-blue;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }

    span {
      font-size: 15px;
      color: $deep-purple;
      opacity: 0.57;
    }
  }

  .settings-user-details {
    width: 55%;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .user-type {
      font-size: 15px;
      color: $deep-purple;
      opacity: 0.57;
    }

    .settings-tags {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }

  .settings-user-actions {
    height: 68px;
    display: flex;
    align-items: flex-end;

    button:first-child {
      margin-right: 16px;
    }

    button {
      height: 50%;
      outline: none;
      border: none;
      padding: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        cursor: default;
      }

      img {
        height: 20px;
      }
    }
  }
}
