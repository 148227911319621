@import '../../styles/variables';

.single-stat-container {
  width: 24%;
  height: 70%;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.pink {
    .single-stat {
      span {
        color: #F92232;//#E74A78;
      }
    }
  }

  &.full-size {
    height: 100%;
  }

  &.duration {
    .single-stat {
      font-size: 18px;
    }
  }

  label {
    color: $deep-purple;
    opacity: 0.57;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    height: 40%;
  }

  .single-stat {
    height: 60%;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    color: #F92232;//$green-blue;
    margin-right: 8%;

    img {
      height: 20px;
      margin-right: 8px;
    }
  }
}