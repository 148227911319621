@import "../../styles/variables";

.reset-password-page-container {
  height: 100%;
  width: 100%;
  background: $green-bkg;

  .reset-password-page{
    height: calc(100% - 170px);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .reset-password {
      margin-top: 5%;
      height: 60%;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        color: $deep-purple;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
      }

      .inputs {
        height: 45%;
        padding-bottom: 5%;
        max-height: 200px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        label {
          color: $pink;
        }
      }

      a {
        font-size: 15px;
        line-height: 23px;
        color: $deep-purple;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.6;
      }
    }

    .success{
      margin-top: 5%;
      height: 60%;
      width: 30%;
      gap:1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        color: $deep-purple;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
      }
      a {
        font-size: 15px;
        line-height: 23px;
        color: $deep-purple;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.6;
      }

      
    }
  }
}