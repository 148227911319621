@import "../../styles/variables";

.import-cases-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .cross {
    position: fixed;
    right: 34%;
    top: 20%;
    height: 15px;
    cursor: pointer;
  }

  .error-campaign {
    &.processed-with-errors  {
      height: 70%;
      justify-content: space-around;
      align-items: center;
    }

    .error-campaign-title {
      height: 18%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        height: calc(50% + 12.5px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2.65px;
      }
    }
    .error-campaign-content {
      width: 100%;
      height: 64%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      color: $deep-purple;
      opacity: 0.57;
      padding-bottom: 20px;
      &.processed-with-errors {
        height: auto;
        .error-campaign-subtitle {
          text-align: center;
        }
      }

      .error-campaign-subtitle {
        font-size: 16px;
        line-height: 23px;
      }

      .log-details {
        padding: 15px 10px;
        height: 75%;
        overflow-y: auto;
        background-color: $light-green;
        box-sizing: border-box;
        .log-entry {
          ul {
            line-height: 23px;
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }
    .error-campaign-btns {
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
      }

      .ok {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
        padding-top: 42px;
      }
    }
  }


  .import-cases, .error-campaign {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .xhr {
      opacity: 0.2;
      pointer-events: none;
    }

    .import-cases-title {
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        color: $green-blue;
        font-size: 24px;
        line-height: 36px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }
    }

    .import-cases-content {
      height: 62%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .results {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }

      .subtitle {
        color:$deep-purple;
        opacity: 0.8;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 2.65px;
        font-family: 'Montserrat', sans-serif;

        &.title {
          margin-bottom: 20px;
        }
      }

      .amount {
        border: 1.1px solid #CFCFCF;
        border-radius: 2px;
        width: 30%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        span {
          color: $green-blue;
          font-size: 24px;
        }
      }
    }

    .import-cases-dropzone {
      width: 100%;
      height: 50%;
    }

    .import-cases-btns {
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
      }

      .next {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
      }

      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
      }
    }
  }
}