$main: #DDD;
$small: 10px;
$deep-purple: #00124B;
$green-bkg: linear-gradient(78.62deg, #EFF0F9 0%, rgba(230,246,251, 0.97) 100%);
$pink: #E74A78;
$dark-grey: #3C3B4B;
$medium-grey: #777B8D;
$green-blue: #0066CA;
$light-green: #EFF3F9;
$promise-green-bkg: #a1f1a1;
$promise-green-text: #19a119;
$chattingo-orange: #FF6C01;
$chattigo-bgk1: linear-gradient(-90deg, #da4d4f 0%, #f17369 39%, #f19b5c 100%);
$chattigo-bgk2: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(225,164,140,0.21) 100%);
$pelikanus-bgk: #0000000a;
$pelikanus_color2 : #424242;
$pelikanus_color1: #00FFFF;


