@import "../../styles/variables";

.call-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #F92232;//$green-blue;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &.active {
    border-color: #e20616;//$green-blue;
  }

  &.pink {
    color: #F92232;//$pink;

    &.active {
      border-color: #e20616;//$pink;
    }
  }

  .call {
    display: flex;
    align-items: center;

    span {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.4px;
      font-weight: 500;
    }

    img {
      margin-left: 8px;
      height: 20px;
    }

    .phone {
      margin: auto 18px;
    }
  }

  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reason {
      //width: 45%;
      height: 30px;
      box-sizing: border-box;
      border: 1.1px solid $green-blue;
      border-radius: 3.3px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.pink {
        border-color: $pink;
      }

      span {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.55px;
      }
    }
  }

  .download {
    margin-right: 18px;
    img {
      height: 20px;
    }

    button {
      text-decoration: none;
      display: inline-block;
      background: transparent;
      border: none;
      cursor: pointer;
      user-select: none;
      outline: 0;
    }
  }

  .duration {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 20px;
      margin-right: 8px;
    }
    span {
      font-size: 14px;
      line-height: 15px;
    }
    .arrow {
      width: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .anchor {
        height: 15px;
        width: auto;
        margin: 0;
      }
    }
  }
}