@import "../../styles/variables";

.custom-button-container {
  width: 280px;
  height: 46px;
  .custom-button {
    padding: 3px 12px 0 12px;
    height: 100%;
    width: 100%;
    outline: none;
    border: 1px solid;
    font-size: 18px;
    letter-spacing: 1.93px;
    border-radius: 22.5px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    &.login-btn {
      color: white;
      background-color: $green-blue;
      border-color: $green-blue;
    }

    &.forgot-password-btn, &.reset-password-btn {
      color: $green-blue;
      border-color: $green-blue;
      background-color: transparent;
    }
  }
}

.custom-button-container-chattigo {
  width: 280px;
  height: 46px;
  .custom-button {
    padding: 3px 12px 0 12px;
    height: 100%;
    width: 100%;
    outline: none;
    border: 1px solid;
    font-size: 18px;
    letter-spacing: 1.93px;
    border-radius: 22.5px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    &.login-btn {
      color: $chattingo-orange;
      background-color: white;
      border-color: $chattingo-orange;
    }
    &:hover{
      background-color: rgba(255, 108, 1, .2);
    }

    &.forgot-password-btn, &.reset-password-btn {
      color: $chattingo-orange;
      border-color: $chattingo-orange;
      background-color: transparent;
      &:hover{
        background-color: rgba(255, 108, 1, .2);
      }
    }
  }
}

.custom-button-container-pelikanus {
  width: 280px;
  height: 46px;
  .custom-button {
    padding: 3px 12px 0 12px;
    height: 100%;
    color: #4d4d4d;    
    width: 100%;
    outline: none;
    border: none;
    font-size: 18px;
    letter-spacing: 1.93px;
    border-radius: 22.5px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: all 0.1s ease;
    &.login-btn {
      color: $pelikanus_color1;
      background-color: $pelikanus_color2;
      transition: all 0.1s ease;    }
    &:hover{
      opacity: .9;
    }

    &.forgot-password-btn, &.reset-password-btn {
      color: $pelikanus_color2;
      border: 1px solid $pelikanus_color2;
      background-color: transparent;
      transition: all 0.1s ease;
      &:hover{
        transition: all 0.1s ease;
        background-color: $pelikanus_color1;
        border: 1px solid $pelikanus_color2;
      }
    }
  }
}