@import "../../styles/variables";


.campaign-success {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .cross {
    position: absolute;
    right: 5%;
    top: 3%;
    height: 15px;
    cursor: pointer;
  }

  .campaign-success-content {
    width: 75%;
    height: 100%;

    .success {
      height: 82%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        height: 30px;
        margin-bottom: 60px;
      }

      span {
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        opacity: 0.8;
        font-family: 'Montserrat', sans-serif;
        width: 60%;
        text-align: center;
      }
    }

    .campaign-success-btns {
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      a, button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
        text-decoration: none;
      }

      .ok {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
      }
    }
  }

}