@import '../../styles/variables';

.new-case-form-container {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .cross {
    position: absolute;
    right: 5%;
    top: 3%;
    height: 15px;
    cursor: pointer;
  }

  .new-case-form-btns {
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &.success {
      width: 60%;
    }

    button {
      outline: none;
      border: none;
      border-radius: 22.5px;
      cursor: pointer;
    }

    .next {
      color: white;
      background-color: $green-blue;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.93px;
      width: 65%;
      height: 42px;
    }

    .cancel {
      color: $green-blue;
      line-height: 20px;
      font-size: 17px;
      width: 65%;
      height: 42px;
      background-color: transparent;
    }
  }

  .new-case-form {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .xhr {
      opacity: 0.2;
      pointer-events: none;
    }

    .new-case-form-title {
      height: 18%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: $deep-purple;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2.65px;
      }
    }

    .new-case-form-content {
      height: 64%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-bottom: 8%;
      box-sizing: border-box;

      input::placeholder {
        color: #cfcfcf;
      }

      .row {
        height: 25%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .field {
          display: flex;
          flex-direction: column;
          width: 45%;

          > input {
            font-size: 15px;
            outline: none;
            color: $deep-purple !important;
            border: 1.1px solid #cfcfcf;
            height: 30px;
            box-sizing: border-box;
            margin: 0;
            padding: 0 10px;
          }
        }
      }

      label {
        color: $deep-purple;
        font-size: 15px;
        line-height: 23px;
        opacity: 0.57;
        margin-bottom: 1px;

        &.error {
          font-size: 12px;
          margin-top: 1px;
          color: $pink;
          height: 15px;
          opacity: 1;
          white-space: nowrap;
        }
      }

      .new-case-form-inputs {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .new-case-form-input {
          display: flex;
          flex-direction: column;
          width: 45%;
          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cfcfcf;
            font-size: 20px;
            line-height: 30px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            outline: none;
            color: $green-blue;
            box-sizing: border-box;
          }

        }
      }
    }
  }
}