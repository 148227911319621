@import "../../styles/variables";

.bot-campaign-container {
  height: 100%;
  
  .bot-campaign {
    height: 100%;
    padding: 0 3% 0 3%;
    
    .bot-campaign-header {
      height: 14%;
      display: flex;
      align-content: center;
      border-bottom: 1px solid rgba(231, 231, 231, 0.89);
      
      
      .bot-campaign-title {
        display: flex;
        align-items: center;
        width: 70%;
        color: rgba(0, 18, 75, 0.6);
        font-size: 16px;
        letter-spacing: 0.95px;
        line-height: 25px;
        
        span {
          font-family: 'Montserrat', sans-serif;
        }
      }
      
      .bot-campaign-input {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        input {
          line-height: 14px;
        }
      }
      
      .bot-campaign-add {
        width: 5%;
        display: flex;
        align-items: center;
        margin-left: 40px;
        
        button {
          width: 100%;
          padding: 0;
          outline: none;
          cursor: pointer;
          border: none;
          background-color: transparent;
          
          img {
            max-height: 38px;
          }
        }
      }
    }
    
    .bot-campaign-body {
      height: 86%;
      position: relative;
      
      .bot-campaign-filters {
        height: 11.627907%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .bot-campaign-quick-filters {
          button {
            color: #ACACAC;
            cursor: pointer;
            outline: none;
            border: none;
            background-color: transparent;
            padding: 2px 15px;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.67px;
            font-family: 'Montserrat', sans-serif;
            
            &.active {
              color: $deep-purple;
            }
            &:hover{
              color: $deep-purple;
            }
          }
        }

        .bot-campaign-sort {
          display: flex;
          align-items: center;
          
          span {
            color: #8B8B8B;
            font-size: 15px;
            line-height: 18px;
          }
          
          button {
            padding: 0;
            outline: none;
            cursor: pointer;
            border: none;
            background-color: transparent;

            &:hover{
              transform: scale(1.05);
              transition: all 0.3s ease;
            }

            img {
              margin-left: 18px;
              height: 18px;
            }
          }
        }
      }
      
      .bot-campaign-content-container {
        height: calc(88.372093% - 70px);
        
        .bot-campaign-content {
          max-height: 100%;
          box-sizing: border-box;
          overflow-y: auto;
          overflow-x: hidden;
          margin-bottom: 70px;
          
          div[class^='MuiGrid-item-'] {
            height: 160px !important;
          }

          
          
          a {
            height: 100%;
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            padding: 0;
            cursor: pointer;
            text-decoration: none;
          

            .card-container {
              box-sizing: border-box;
              height: 100%;

              width: 100%;
              display: flex;
              flex-direction: column;
              padding: .5em 1em;
              justify-content: space-around;
              
                         
              
              .card-title {
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                h4 {
                  width: 90%;
                  margin: 0;
                  font-size: 17px;
                  line-height: 22px;
                  font-family: 'Montserrat', sans-serif;
                  color: #F92232; //$green-blue;
                  text-align: left;
                  font-weight: normal;
                }
                
                span {
                  text-align: left;
                  color: #ACACAC;
                  font-size: 15px;
                  line-height: 15px;
                }
              }
              .card_title_custom{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                h4 {
                  width: 90%;
                  margin: 0;
                  font-size: 17px;
                  line-height: 22px;
                  font-family: 'Montserrat', sans-serif;
                  color: #F92232; //$green-blue;
                  text-align: left;
                  font-weight: normal;
                }
                
                span {
                  text-align: left;
                  color: #ACACAC;
                  font-size: 15px;
                  line-height: 15px;
                }
              }
              .card_data_custom{
                display: flex;
                color: rgba(0, 18, 75, 0.6);
                flex-direction: column;
                align-items: flex-start;
                .progress_bar_box{
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  margin-top: .5em;
                }
                .section-progress{
                  width: 100%;
                  height: 40%;
                  display: grid;
                  grid-template-columns: 60% 40%;
                  justify-content: space-between;
                  align-items: baseline;
                  span{
                    font-size: 12px;
                    line-height: 14px;
                    text-align: start;
                  }              
                }
              }
              
            }

            .card-container-chattigo:hover{        
              background-color: rgba(255, 108, 1, .2);
              border-radius: 5px;
              border: 1px solid #FF6C01;
            }
            .card-container-chattigo {
              box-sizing: border-box;
              
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              padding: 15px 25px;
           
              
              .card-title {
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                h4 {
                  width: 90%;
                  margin: 0;
                  font-size: 17px;
                  line-height: 22px;
                  font-family: 'Montserrat', sans-serif;
                  color: #F92232; //$green-blue;
                  text-align: left;
                  font-weight: normal;
                }
                
                span {
                  text-align: left;
                  color: #ACACAC;
                  font-size: 15px;
                  line-height: 15px;
                }
              }
         
              .card_data_custom{
                display: flex;
                color: rgba(0, 18, 75, 0.6);
                flex-direction: column;
                align-items: flex-start;
                .progress_bar_box{
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  margin-top: .5em;
                }
                .section-progress{
                  width: 100%;
                  height: 40%;
                  display: grid;
                  grid-template-columns: 60% 40%;
                  justify-content: space-between;
                  align-items: baseline;
                  span{
                    font-size: 12px;
                    line-height: 14px;
                    text-align: start;
                  }              
                }
              }
            }

            .card-container-pelikanus:hover{        
              background-color: #eee;
              border-radius: 5px;
            }
            .card-container-pelikanus {
              // border: #F92232 1px solid;
              box-sizing: border-box;              
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              padding: 15px 25px;
              
              .card-title {
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                h4 {
                  width: 90%;
                  margin: 0;
                  font-size: 17px;
                  line-height: 22px;
                  font-family: 'Montserrat', sans-serif;
                  color: #F92232; //$green-blue;
                  text-align: left;
                  font-weight: normal;
                }
                
                span {
                  text-align: left;
                  color: #ACACAC;
                  font-size: 15px;
                  line-height: 15px;
                }
              }
                       
              .card_data_custom{
                display: flex;
                color: rgba(0, 18, 75, 0.6);
                flex-direction: column;
                align-items: flex-start;
                .progress_bar_box{
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  margin-top: .5em;
                }
                .section-progress{
                  width: 100%;
                  height: 40%;
                  display: grid;
                  grid-template-columns: 60% 40%;
                  justify-content: space-between;
                  align-items: baseline;
                  span{
                    font-size: 12px;
                    line-height: 14px;
                    text-align: start;
                  }              
                }
              }
            }

          }
        }
      }

    }
  }
}