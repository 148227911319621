@import '../../styles/variables.scss';

.edit-form-container {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .cross {
    position: absolute;
    right: 5%;
    top: 3%;
    height: 15px;
    cursor: pointer;
  }

  .edit-form {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .xhr {
      opacity: 0.2;
      pointer-events: none;
    }

    .edit-form-header {
      width: 100%;
      height: 18%;
      flex-direction: column;
      display: flex;
      justify-content: flex-end;

      .edit-form-title {
        text-align: left;
        width: 100%;
        font-size: 21px;
        line-height: 31px;
        color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        &.fixed {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }

    .edit-form-content {
      width: 100%;
      height: 70%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px 0;
      
      //checkbox
      .MuiTypography-body1{
        color: #001248;
        font-size: 15px ;
        opacity: 0.57;

      }

      .edit-form-first-row {
        height: 80px;
        display: flex;
        align-items: flex-start;

        label {
          color: $deep-purple;
          font-size: 15px;
          line-height: 23px;
          opacity: 0.57;
          margin-bottom: 2px;
          white-space: nowrap;
        }

        .edit-form-cases {
          margin-right: 20px;
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .cases {
            background-color: #EFF0F9;
            border-radius: 2px;
            display: flex;
            align-items: center;
            height: 30px;

            img {
              height: 20px;
              margin-left: 10px;
              margin-right: 15px;
            }

            span {
              color: $green-blue;
              font-size: 15px;
              line-height: 23px;
            }
          }
        }

        .edit-form-max-calls {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 40%;

          .max-calls {
            border: 1.1px solid #CFCFCF;
            border-radius: 2px;
            display: flex;
            align-items: center;
            height: 30px;
            justify-content: space-between;
            box-sizing: border-box;

            img {
              height: 20px;
              margin-left: 10px;
              margin-right: 15px;
            }

            span {
              margin-right: 50%;
              color: $deep-purple;
              font-size: 15px;
              line-height: 23px;
            }

            .max-calls-btns {
              height: 100%;
              display: flex;
              flex-direction: column;
              width: 30px;

              button {
                height: 15px;
                padding: 0;
                cursor: pointer;
                outline: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EFF0F9;
              }

              .up {
                img {
                  height: 7px;
                  margin: 0;
                  transform: rotateX(180deg);
                }
              }

              .down {
                img {
                  height: 7px;
                  margin: 0;
                }
              }
            }

          }
        }
      }

      .edit-form-second-row {
        height: 80px;
        .edit-form-picker {
          label {
            display: flex;
            color: $deep-purple;
            font-size: 15px;
            line-height: 23px;
            opacity: 0.57;
            margin-bottom: 2px;

            .from-text {
              width: calc(40% + 20px);
            }

            .to-text {
              width: calc(40% + 20px);
            }

            &.error {
              color: $pink;
              font-size: 12px;
              margin-top: 1%;
              height: 15px;
              opacity: 1;
            }
          }

          .edit-form-picker-content {
            display: flex;
          }
        }
      }

      .edit-form-third-row {
        height: 80px;
        .edit-form-start {
          display: flex;
          flex-direction: column;

          label {
            color: $deep-purple;
            font-size: 15px;
            line-height: 23px;
            opacity: 0.57;
            margin-bottom: 2px;

            &.error {
              color: $pink;
              font-size: 12px;
              margin-top: 1%;
              height: 15px;
              opacity: 1;
            }
          }

          .edit-form-start-content {
            display: flex;
            justify-content: space-between;

            .when {
              display: flex;
              width: 60%;
              cursor: pointer;

              span {
                color: #8B8B8B;
                font-size: 15px;
                line-height: 23px;
                opacity: 0.57;
              }

              .now {
                border: 1.1px solid #CFCFCF;
                border-right: none;
                width: 50%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.selected {
                  border: 1.1px solid $green-blue;

                  span {
                    color: $deep-purple;
                    opacity: 1;
                  }
                }
              }

              .program {
                height: 30px;
                width: 50%;
                border: 1.1px solid #CFCFCF;
                border-left: none;
                display: flex;
                justify-content: center;
                align-items: center;

                &.selected {
                  border: 1.1px solid $green-blue;

                  span {
                    color: $deep-purple;
                    opacity: 1;
                  }
                }
              }
            }

            .date {
              width: 36%;

              input[type=date] {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 6px 2px 6px 8px;
                box-sizing: border-box;
                vertical-align: center;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  display: none;
                }

                &::-webkit-calendar-picker-indicator {
                  color: rgba(0, 0, 0, 0);
                  background: url('../../assets/calendar.png') no-repeat;
                  background-size: contain;
                  opacity: 1;
                  display: block;
                  height: 10px;
                  cursor: pointer;
                }

                &::-webkit-clear-button {
                  margin-right: 10px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .edit-form-fourth-row {
        width: 100%;
      }
    }

    .edit-form-delete {
      display: flex;
      width: 100%;
      align-items: flex-start;
      height: 8%;

      button {
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: flex-start;

        img {
          height: 20px;
        }

        span {
          margin-left: 10px;
          color: #E74A78;
          font-size: 15px;
          line-height: 23px;
        }
      }
    }

    .edit-form-btns {
      height: 18%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .submit-btn-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      button {
        outline: none;
        border: none;
        border-radius: 22.5px;
        cursor: pointer;
      }

      .confirm {
        color: white;
        background-color: $green-blue;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.93px;
        width: 65%;
        height: 42px;
      }

      .cancel {
        color: $green-blue;
        line-height: 20px;
        font-size: 17px;
        width: 65%;
        height: 42px;
        background-color: transparent;
      }
    }
  }
}