
.input-container {
  .img-input {
    display: flex;
    justify-content: flex-end;
    border: 1px solid #ced4da;
    border-radius: 4px;
    align-items: center;
    background-color: white;
    opacity: 0.7;
    
    &.search {
      justify-content: flex-start;
    }

      img {
        height: 20px;
        padding-right: .6rem;
      }
    }

}