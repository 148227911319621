@import "../../styles/variables";

.bot-inbound-navbar-container {
    height: 9%;
    padding-right: 8.75%;

    .bot-inbound-navbar {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(231, 231, 231, 0.89);

        .bot-inbound-navbar-section-container {
            display: flex;
            align-items: center;
            width: 70%;

            li {
                list-style-type: none;
            }

            li+li {
                margin-left: 2%;
            }

            .bot-inbound-navbar-section {
                color: rgba(0, 18, 75, 0.6);
                font-size: 16px;
                letter-spacing: 0.95px;
                line-height: 25px;
                font-family: 'Montserrat', sans-serif;
                cursor: pointer;

                &.active {
                    color: $deep-purple;
                }
            }
        }

        .bot-inbound-navbar-input {
            width: 25%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            input {
                line-height: 14px;
            }
        }

        .bot-inbound-navbar-add {
            width: 5%;
            display: flex;
            align-items: center;
            margin-left: 40px;

            button {
                width: 100%;
                padding: 0;
                outline: none;
                cursor: pointer;
                border: none;
                background-color: transparent;

                img {
                    max-height: 38px;
                }
            }
        }
    }
}