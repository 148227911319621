@import '../../styles/variables';

.page-not-found-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .page-not-found {
    width: 40%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    span {
      padding-top: 30px;
      color: $green-blue;
      font-size: 26px;
    }

    p {
      padding-top: 30px;
      font-size: 15px;
      text-align: center;
      margin: 0;
    }

    a {
      margin-top: 30px;
      text-decoration: none;
      font-size: 15px;
      color: $deep-purple;
      border: 1px solid $green-blue;
      padding: 4px 10px;
      border-radius: 2px;
    }
  }
}