@import '../../styles/variables';

.inbound-table-container { 
  .inbound-table {
    position: relative;

    svg {
      height: 30px;
    }

    &.default {
      th:first-child {
        cursor: default;
      }
      td:first-child {
        cursor: default;
      }
    }

    .react-bootstrap-table-pagination {
      width: calc(100% - 1.98px);
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.99px solid #CFCFCF;
      border-top: none;

      div.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
        position: absolute;
        left: 0;

        .react-bootstrap-table-pagination-total {
          color: rgba(0, 18, 75, 0.5);
          font-size: 15px;
          line-height: 18px;
          margin-left: 10px;
          cursor: default;
        }

        &.react-bootstrap-table-pagination-list {
          left: 0;
          position: relative;
          width: auto;
          max-width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;

          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;
            list-style: none;

            li.active {
              a {
                font-weight: 500;
                color: $green-blue;
              }
            }

            li {
              padding: 0 10px;

              a {
                text-decoration: none;
                cursor: pointer;
                font-size: 15px;
                line-height: 12px;
                color: $deep-purple;
              }
            }
          }
        }
      }
    }

    .react-bootstrap-table {
      .table {
        width: 100%;
        border-collapse: collapse;

        .table-checkbox {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .checkbox-content {
            width: 15px;
            height: 15px;
            border: 1.1px solid #ACACAC;
            border-radius: 3.3px;
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            &.default {
              cursor: default;
            }

            &.header {
              &.selected {
                .checkbox {
                  width: 60%;
                  height: 25%;
                  background-color: $deep-purple;
                  opacity: 0.9;
                  border-radius: 10px;
                }
              }
            }

            &.body {
              &.selected {
                .checkbox {
                  width: 70%;
                  height: 70%;
                  background-color: #e20616;//$green-blue;
                  border-radius: 2px;
                }
              }
            }
          }
        }

        thead tr {
          border-radius: 2px 2px 0 0;
          background-color: #e20616;//$green-blue;
          height: 40px;
          border: 0.99px solid #e20616;//$green-blue;
          border-bottom: none;
          cursor: default;

          th {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            text-align: left;
            padding-left: 10px;
          }
        }

        tbody {
          border-left: 0.99px solid #CFCFCF;
          border-right: 0.99px solid #CFCFCF;

          tr {
            border-bottom: 0.99px solid #CFCFCF;
            cursor: pointer;
            height: 38px;

            td {
              border-left: 0.99px solid #CFCFCF;
              padding: 4px 10px;
              font-size: 14px;
              line-height: 15px;
              color: #F92232; //$green-blue; ff010d
              white-space: nowrap;
              text-overflow: ellipsis;

              .call {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }

                .call-count {
                  width: 35%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-weight: 500;
                  }
                }

                .call-duration {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 65%;
                }
              }

              .compromise {
                display: flex;
                align-items: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }
              }

              .callEndDateTime {
                display: flex;
                align-items: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }
              }
            }

            td:first-child {
              border-left: none;
            }
          }
        }

      }
    }
  }
}


.inbound-table-container-chattingo{
  .inbound-table {
    position: relative;

    svg {
      height: 30px;
    }

    &.default {
      th:first-child {
        cursor: default;
      }
      td:first-child {
        cursor: default;
      }
    }

    .react-bootstrap-table-pagination {
      width: calc(100% - 1.98px);
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.99px solid #CFCFCF;
      border-top: none;

      div.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
        position: absolute;
        left: 0;

        .react-bootstrap-table-pagination-total {
          color: rgba(0, 18, 75, 0.5);
          font-size: 15px;
          line-height: 18px;
          margin-left: 10px;
          cursor: default;
        }

        &.react-bootstrap-table-pagination-list {
          left: 0;
          position: relative;
          width: auto;
          max-width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;

          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;
            list-style: none;

            li.active {
              a {
                font-weight: 500;
                color: $green-blue;
              }
            }

            li {
              padding: 0 10px;

              a {
                text-decoration: none;
                cursor: pointer;
                font-size: 15px;
                line-height: 12px;
                color: $deep-purple;
              }
            }
          }
        }
      }
    }

    .react-bootstrap-table {
      .table {
        width: 100%;
        border-collapse: collapse;

        .table-checkbox {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .checkbox-content {
            width: 15px;
            height: 15px;
            border: 1.1px solid #ACACAC;
            border-radius: 3.3px;
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            &.default {
              cursor: default;
            }

            &.header {
              &.selected {
                .checkbox {
                  width: 60%;
                  height: 25%;
                  background-color: $deep-purple;
                  opacity: 0.9;
                  border-radius: 10px;
                }
              }
            }

            &.body {
              &.selected {
                .checkbox {
                  width: 70%;
                  height: 70%;
                  background-color: #e20616;//$green-blue;
                  border-radius: 2px;
                }
              }
            }
          }
        }  
          thead{
            background-color: #F8F9FF;
            border: 0.99px solid #CFCFCF;
          }
          thead tr {
            border-radius: 2px 2px 0 0;
            background-color: transparent;
            // background-color: #e20616;//$green-blue;
            height: 40px;
            // border: 0.99px solid #e20616;//$green-blue;
            // border-bottom: none;
            cursor: default;
            
            th {
              color:rgba(0,18,75,0.5);;
            
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            text-align: left;
            padding-left: 10px;
          }
        }
        
        tbody {
          border-left: 0.99px solid #CFCFCF;
          border-right: 0.99px solid #CFCFCF;
          tr:hover{
            transition: all 0.3s ease;
            background-color: #EFF3F9;            
          }
          .react-bs-table-no-data:hover{
            cursor: default;
            background-color: white;

          }

          .react-bs-table-no-data{
            color:rgba(0,18,75,0.5);
          }
          tr {
            border-bottom: 0.99px solid #CFCFCF;
            cursor: pointer;
            height: 38px;
            
            td {
              border-left: 0.99px solid #CFCFCF;
              padding: 4px 10px;
              font-size: 14px;
              line-height: 15px;
              color: #F92232; //$green-blue; ff010d
              white-space: nowrap;
              text-overflow: ellipsis;

              .call {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }

                .call-count {
                  width: 35%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-weight: 500;
                  }
                }

                .call-duration {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 65%;
                }
              }

              .compromise {
                display: flex;
                align-items: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }
              }

              .callEndDateTime {
                display: flex;
                align-items: center;

                img {
                  height: 20px;
                  margin-right: 8px;
                }
              }
            }

            td:first-child {
              border-left: none;
            }
          }
        }

      }
    }
  }


}