@import '../../styles/variables.scss';

div[class^='MuiBackdrop-root-'] {
  background-color: rgba(255, 255, 255, 0.6);
}

.date-picker-container {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid #CFCFCF;
  box-sizing: border-box;

  div[class^='MuiFormControl-root-'] {
    width: 80%;
  }

  &.full {
    width: 100%;
    border: 1.1px solid #CFCFCF;
  }

  div[class^='MuiPaper-root'] {
    box-shadow: 0 6px 42px 6px rgba(0, 0, 0, 0.2);
  }

  img {
    height: 15px;
  }
}

input[class^='MuiInput-input-'] {
  font-size: 15px;
  padding: 0;
}
