@import "../../styles/variables";

.bot-sidebar-container {
  width: 100%;
  height: 100%;

  .bot-sidebar {
    width: 100%;
    height: 100%;
    background-color: $light-green;
    padding-bottom: 100px;
    box-sizing: border-box;

    .bot-sidebar-title {
      height: 10%;
      /*15.929%;/*100px;*/
      display: flex;
      justify-content: flex-start;
      padding-left: 10%;
      align-items: center;
      border-bottom: 1px solid white;

      span {
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        color: $medium-grey;
        letter-spacing: 0.95px;
      }
    }

    .bots {
      // height: calc(100% - 100px);
      height: 100%;
      overflow-y: auto;
      line-height: 50px;
      box-sizing: border-box;
      position: relative;

      .bot-container:hover {
        background-color: rgba(0, 102, 202, 0.1);
        border-bottom: 1px solid rgba(0, 102, 202, 0.1);
        border-right: 3px solid $green-blue;
      }

      .bot-container {
        cursor: pointer;
        width: 100%;
        height: 50px;
        display: flex;
        box-sizing: border-box;
        padding-left: 10%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid white;
        border-right: 3px solid transparent;

        &.active {
          background-color: rgba(0, 102, 202, 0.1);
          border-bottom: 1px solid rgba(0, 102, 202, 0.1);
          border-right: 3px solid $green-blue;

          .bot {
            color: $green-blue;
          }
        }

        .bot {
          color: $medium-grey;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.bot-sidebar-container-chattigo {
  width: 100%;
  height: 100%;

  
  .bot-sidebar {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(218,77,79,1) 0%, rgba(241,115,105,1) 39%, rgba(241,155,92,1) 100%);
    background-attachment: fixed;
    color: white;
    padding-bottom: 100px;
    box-sizing: border-box;
    
    
    
    .bots {
      // height: calc(100% - 100px);
      height: 100%;
      overflow-y: auto;
      line-height: 50px;
      box-sizing: border-box;
      position: relative;

      
      .bot-container:hover {
        background-color: rgba(255, 255, 255, 0.155);
        border-bottom: 1px solid rgba(0, 102, 202, 0.1);
        border-right: 3px solid rgba(235,36,39,1);
      }
      
      .bot-container {
        cursor: pointer;
        width: 100%;
        height: 50px;
        display: flex;
        box-sizing: border-box;
        padding-left: 10%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid white;
        border-right: 3px solid transparent;

        &.active {
          background-color: rgba(255, 255, 255, 0.155);
          border-bottom: 1px solid rgba(0, 102, 202, 0.1);
          border-right: 3px solid rgba(235,36,39,1);
          font-weight: 500;

          .bot {
            color: white;
          }
        }
        .bot {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .bot-sidebar-title {
      height: 50px;
      // padding: .5rem 0;
      display: flex;
      justify-content: flex-start;
      padding-left: 10%;
      align-items: center;
      border-bottom: 1px solid white;
      font-weight: 600;
      
      span {
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        color: white;
        letter-spacing: 0.95px;
      }
    }
  }
}

.bot-sidebar-container-pelikanus {
  width: 100%;
  height: 100%;

  
  .bot-sidebar {
    width: 100%;
    height: 100%;
    background: $pelikanus_color2;
    background-attachment: fixed;
    color: white;
    padding-bottom: 100px;
    box-sizing: border-box;
    
    
    
    .bots {
      // height: calc(100% - 100px);
      height: 100%;
      overflow-y: auto;
      line-height: 50px;
      box-sizing: border-box;
      position: relative;

      
      .bot-container:hover {
        background-color: rgba(255, 255, 255, 0.155);
        border-bottom: 1px solid rgba(0, 102, 202, 0.1);
        border-right: 3px solid #00ffff;
      }
      
      .bot-container {
        cursor: pointer;
        width: 100%;
        height: 50px;
        display: flex;
        box-sizing: border-box;
        padding-left: 10%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid white;
        border-right: 3px solid transparent;

        &.active {
          background-color: rgba(255, 255, 255, 0.155);
          border-bottom: 1px solid rgba(0, 102, 202, 0.1);
          border-right: 3px solid #00ffff;
          font-weight: 500;

          .bot {
            color: $pelikanus_color1;
          }
        }
        .bot {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .bot-sidebar-title {
      height: 50px;
      // padding: .5rem 0;
      display: flex;
      justify-content: flex-start;
      padding-left: 10%;
      align-items: center;
      border-bottom: 1px solid white;
      font-weight: 600;
      
      span {
        font-size: 16px;
        line-height: 25px;
        font-family: 'Montserrat', sans-serif;
        color: white;
        letter-spacing: 0.95px;
      }
    }
  }
}