@import '../../styles/variables';

.double-stat-container {
  display: flex;
  align-items: center;
  width: 48%;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  height: 70%;
  position: relative;

  &.full-size {
    height: 100%;
  }

  label {
    color: $deep-purple;
    opacity: 0.57;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    height: 40%;
  }

  .double-stat-first {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      color: #F92232;//$green-blue;
    }
  }

  .double-stat-second {
    width: 50%;
    border-left: 1px solid #E7E7E7;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      color: #D93464;
    }
  }

  .double-stat-first .double-stat, .double-stat-second .double-stat {
    height: 60%;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    margin-right: 8%;

    img {
      height: 20px;
      margin-right: 8px;
    }
  }
}