@import "../../styles/variables";

.login-page-container {
  height: 100%;
  width: 100%;

  .login-page {
    height: calc(100% - 170px);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .login {
      margin-top: 5%;
      height: 60%;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-family: 'Roboto', sans-serif;

      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 2.65px;
        font-family: 'Montserrat', sans-serif;
        opacity: 0.8;
      }

      .inputs {
        max-height: 200px;
        height: 45%;
        padding-bottom: 5%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .input-container + .input-container {
          margin-top: 5%;
        }

        .subscription-labels-container {
          padding-bottom: 5%;
          height: 50%;

          span {
            font-size: 16px;
            opacity: 0.5;
            
            &.subscription-label {
              font-size: 16px;
              opacity: 0.9;
            }
            line-height: 25px;
            letter-spacing: 1.5px;
            //color: $deep-purple;
            font-family: 'Roboto', sans-serif;
          }

          span + span {
            padding-left: 1%;
          }
        }

      }

      a {
        font-size: 15px;
        line-height: 23px;
        color: $deep-purple;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.57;
      }
    }
  }
}